import Vue from 'vue' // 导入 Vue 模块
import axios from 'axios'
import qs from 'qs'

import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import App from './App' // 导入项目的入口组件
// 导入我们创建的路由配置对象，也可以写成 ./router/, 其默认会找 ./router 目录下的 index.js
import Router from './router/index.js'

import md5 from 'js-md5';
Vue.prototype.$md5 = md5;

import wx from "weixin-js-sdk";
Vue.prototype.$wx = wx;

Vue.prototype.$axios = axios    //全局注册，使用方法为:this.$axios
Vue.prototype.qs = qs           //全局注册，使用方法为:this.qs

Vue.prototype.API1="";
Vue.prototype.API2="https://fjsj68.com/";

import {Base64} from 'js-base64'
Vue.prototype.$Base64 = Base64;

import VueClipBoard from 'vue-clipboard2'
Vue.use(VueClipBoard);

import Vant,{Lazyload} from 'vant';
import 'vant/lib/index.css'
Vue.use(Vant);
Vue.use(Lazyload,{
  error:'https://www.love73.com/404.jpg',
  loading:'https://www.love73.com/loading-spin.svg',
  preLoad: 1.3,//预载高度比例
  attempt: 1,//	尝试次数
  //listenEvents: ['scroll']
})

Vue.use(ElementUI);
Vue.config.productionTip = false

// 创建 Vue 对象，
// 在创建该对象前，我们已经在 ./router/index.js 中使用 Vue.use 完成了插件 Vue Router 的安装
new Vue({
  el: '#app', // 被 Vue 管理的元素的 ID 
  router: Router,  // 将路由配信信息设置到 Vue 中，
  render: h => h(App) // 渲染函数，通过入口组件开始渲染
})